<template>
  <div class="container" style="width:97%;">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="small"
          icon="el-icon-plus"
          style="margin-bottom: 10px"
          @click="clickAddItem">添加
      </el-button>
      <el-input style="width: 300px;margin-left: 30px;" placeholder="请输入关键字" clearable
                v-model="inputSearch" @keydown.enter.prevent="searchItem" @clear="searchItem">
        <template #append>
          <el-button icon="el-icon-search" @click="searchItem"/>
        </template>
      </el-input>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;">
      <el-table-column label="产品名称" align="center">
        <template v-slot="{row}">
          <span>{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成本" align="center">
        <template v-slot="{row}">
          <span>{{ row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="描述" align="center">
        <template v-slot="{row}">
          <span>{{ row.desc }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{row}">
          <span>{{ row.created_at.substring(0,10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="230px"
          fixed="right">
        <template v-slot="{row}">
          <el-button type="primary" size="mini" @click="editItem(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.pageNum"
          :page-size="listQuery.pageCount"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"/>
    </div>
    <!--    点击添加按钮-->
    <el-dialog width="30%" v-model="dialogFormVisible" :close-on-click-modal="false" :before-close="handleClose"
               title="项目添加">
      <el-form
          ref="addForm"
          :model="formModel"
          :rules="rules"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" required>
              <el-input style="width: 200px"
                        prop="productname"
                        v-model="formModel.name"
                        placeholder="请填写产品名称"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品成本" required>
              <el-input style="width: 200px"
                        type="number"
                        prop="productprice"
                        @blur="inputBlur('price')"
                        v-model="formModel.price"
                        placeholder="请填写产品成本"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品描述">
              <el-input style="width: 200px"
                        v-model="formModel.desc"
                        placeholder="请填写产品描述"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div name="footer" class="dialog-footer" style="float: right;border: 10px">
        <el-button type="primary" @click="sureAddItem()">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <el-dialog width="30%" v-model="dialogFormEditVisible" :close-on-click-modal="false" :before-close="handleClose"
               title="编辑">
      <el-form
          ref="dataForm"
          :model="formModel"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" required>
              <el-input style="width: 200px"
                        v-model="formModel.name"
                        disabled
                        placeholder="请填写产品名称"/>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品成本" required>
              <el-input style="width: 200px"
                        type="number"
                        v-model="formModel.price"
                        @blur="inputBlur('price')"
                        placeholder="请填写产品成本"/>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品描述">
              <el-input style="width: 200px"
                        v-model="formModel.desc"
                        placeholder="请填写产品描述"/>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div name="footer" class="dialog-footer" style="float: right;border: 10px">
        <!--        <el-button @click="dialogFormVisible = false">取消</el-button>-->

        <el-button type="primary" @click="sureEditItem()">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

  </div>

</template>

<script>
export default {
  name: "Item",
  data() {
    return {
      inputSearch: '',

      //判斷是否是添加按鈕從而走不同的方法
      dialogImageUrl: '',
      dialogVisible: false,

      dialogVisitVisible: false,
      dialogFormEditVisible: false,

      list: [],
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      listQuery: {
        "pageCount": 10,
        "pageNum": 1,
      },
      formModel: {
        name: '',
        price: 0.0,
        id: '',
        desc: ''
      },
      rules: {
        productname: [{
          required: true, message: '请填写产品名称', trigger: 'blur'
        }],
        productprice: [
          {required: true, message: '请填写产品成本', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    inputBlur(string){
      if(string === 'price'){
        this.formModel.price = parseFloat(this.formModel.price)
        //保留2位小数
        // this.formModel.price = this.formatNumber(this.formModel.price)
      }
    },
    formatNumber(number) {
      const str = number.toString();

      if (str.includes('.')) {
        const decimalLength = str.split('.')[1].length;

        if (decimalLength > 2) {
          return number.toFixed(2);
        } else if (decimalLength === 1) {
          return number.toFixed(1);
        }
      }
      return number;
    },
    searchItem(){
      // this.listLoading = true
      if(this.inputSearch === ''){
        this.listQuery = {
          "pageCount": 10,
          "pageNum": 1,
        }
      }else{
        this.listQuery = {
          "pageCount": 10,
          "pageNum": 1,
          "search": {
            "Name": this.inputSearch
          }
        }
      }
      this.getList()
      //     .then(res => {
      //   if (res.data.code === 0) {
      //     this.list = res.data.msg.products
      //     this.total = res.data.msg.total
      //     this.$message({
      //       message: '查询成功',
      //       type: 'success'
      //     })
      //     this.listLoading = false
      //   } else {
      //     this.$message({
      //       message: res.data.msg,
      //       type: 'error'
      //     })
      //   }
      // })
    },
    clickAddItem() {
      this.formModel = {
        name: '',
        price: '',
        desc: '',
      }
      this.dialogFormVisible = true
    },
    editItem(row) {
      this.formModel = {
        name: row.name,
        price: row.price,
        id: row.id,
        desc: row.desc,
      }
      this.dialogFormEditVisible = true

    },
    sureEditItem() {
      if (this.formModel.price === '' || this.formModel.name==='') {
        this.$message({
          message: "编辑失败，部分必填信息为空",
          type: 'error'
        })
        return
      }
      this.formModel.price = parseFloat(this.formModel.price)
      this.$axios({
        url: this.localpath + 'saleproject/editProduct',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(this.formModel),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.dialogFormEditVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    },
    sureAddItem() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if (this.formModel.price === '') {
            this.formModel.price = 0.0
          }
          this.formModel.price = parseFloat(this.formModel.price)

          this.$axios({
            url: this.localpath + 'saleproject/createProduct',
            method: 'post',
            //发送格式为json
            data: JSON.stringify(this.formModel),
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then(res => {
            if (res.data.code === 0) {
              this.dialogFormVisible = false
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.getList()
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      });
    },
    getList() {
      this.dialogFormVisible = false
      this.listLoading = true
      this.$axios({
        url: this.localpath + 'saleproject/listProduct',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(this.listQuery),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.list = res.data.msg.products
          this.total = res.data.msg.total

          this.listLoading = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭吗？内容不会被保存')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    currPageChange(Page) {
      this.listQuery.pageNum = Page
      this.getList()
    },
    pageSizeChange(Perpage) {
      this.listQuery.pageCount = Perpage
      this.listQuery.pageNum = 1
      this.getList()
    }
  },
}
</script>

<style scoped>
#upload {
  margin-left: 20px;
  margin-top: 15px;
}

#upload :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 55px;
}

.uploadpdf {
  margin-left: 20px;
  /*margin-top: 15px;*/
}

.uploadpdf :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 0;
}

.uploadpdf :deep() .el-icon-close {
  display: none;
}

.uploadpdf :deep() .el-icon-close-tip {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#upload :deep() .el-upload {
  text-align: left;
}
</style>
